/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { StaticQuery, graphql } from 'gatsby';

// Components
import {Container, Row, Col} from '../html/Layout'
import { P, H2, H5 } from '../html/Fonts';
import { baseStyles } from '../html/_config';
import TimeIndicator from '../molecules/TimeIndicator';
import { Link } from '../html/Links';
import { urlMaker } from '../../utility/blog';


const NewBlogPost = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(    
          sort: {
            fields: [frontmatter___date]
            order: DESC
          }
          limit: 1
        ) {
          edges {
            node {
              excerpt(pruneLength: 150)
              fileAbsolutePath
              frontmatter {
                title
                date
                subtitle
                image
              }
            }
          }
        }
      }
    `}
    render={data => (
      data.allMarkdownRemark.edges.length > 0 ?
      <Container as='article' fluid css={css`background: ${baseStyles.colorQuinary}; padding: 60px 0!important; img { max-width: 100%; }`}>
        <Row as='section' css={css`max-width: ${baseStyles.maxWidth}px; margin: 0 auto!important;`}>
          <Col xs={4}>
            <div css={css`
              max-width: 100%;
              max-height: 400px;
              overflow: hidden;
            `}>
              <img src={data.allMarkdownRemark.edges[0].node.frontmatter.image} alt={data.allMarkdownRemark.edges[0].node.frontmatter.title}/>
            </div>
          </Col>
          <Col xs={8} css={css`max-width: 800px!important; padding-left: 45px!important;`}>
            <TimeIndicator time={data.allMarkdownRemark.edges[0].node.frontmatter.date}/>
            <H2 css={css`display: inline-block; margin-bottom: 30px; border-bottom: 2px solid ${baseStyles.colorPrimary};`}>{data.allMarkdownRemark.edges[0].node.frontmatter.title}</H2>
            <H5 css={css`margin-bottom: 30px;`}>{data.allMarkdownRemark.edges[0].node.frontmatter.subtitle}</H5>
            <P>{data.allMarkdownRemark.edges[0].node.excerpt}</P>
            <Link
              css={css`color: ${baseStyles.colorPrimary}`}
              to={urlMaker(data.allMarkdownRemark.edges[0].node.fileAbsolutePath)}
            >
              Read More...
            </Link>
          </Col>
        </Row>
      </Container>
      :
      <Container as='section' fluid css={css`background: ${baseStyles.colorQuinary}; padding: 60px 0!important; img { max-width: 100%; }`}>
        <Row as='section' css={css`max-width: ${baseStyles.maxWidth}px; margin: 0 auto!important;`}>
          <Col>
            <H2 css={css`margin-bottom: 0;`}>Stories of Care Coming Soon...</H2>
          </Col>
        </Row>
      </Container>
    )}/>
  )

export default NewBlogPost