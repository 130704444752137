import React from "react"
import { baseStyles } from "../components/html/_config"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionBlock from "../components/page-elements/SectionBlock"
import NewBlogPost from "../components/page-elements/NewBlogPost"
import AboutAuthor from "../components/page-elements/AboutAuthor"
import { P } from "../components/html/Fonts"

// Images
import { ImgBookCover } from "../components/images/ImgBookCover";


const IndexPage = () => (
  <Layout>
    <SEO 
      title="Lessons of Faith on the Road to End of Life Care"
      description="If you or someone you know is considering the care of a loved one, this book is for you. In no way a 'how to' book, it nevertheless flings open the windows into the soul of the matter, preparing you mentally, emotionally, and spiritually for one of the greatest ministries of all time, caring for another." 
      keywords={[]}
    />
    <SectionBlock
      title="Honor Thy Parents"
      subtitle="Lessons of Faith on the Road to End of Life Care"
      img={<ImgBookCover />}
    >
      <P>If you or someone you know is considering the care of a loved one, this book is for you. In no way a 'how to' book, it nevertheless flings open the windows into the soul of the matter, preparing you mentally, emotionally, and spiritually for one of the greatest ministries of all time, caring for another.</P>
      <P>Honor Thy Parents endeavors to shed light on both the hardships and blessings of end of life care. It is a heartwarming account that is sure to inspire you and give thought to those you love in ways you may never have imagined.</P>
    </SectionBlock>
    <NewBlogPost backgroundColor={baseStyles.colorQuinary} />
    <AboutAuthor />
  </Layout>
)

export default IndexPage
