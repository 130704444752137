// eslint-disable-next-line
import React from 'react'
/** @jsx jsx */
import { jsx, css } from '@emotion/core'

import { Container, Row, Col } from '../html/Layout';
import { ImgNickBook } from '../images/ImgNickBook';
import { P, H2 } from '../html/Fonts';

const AboutAuthor = () => (
  <Container fluid css={css``}>
    <Row>
      <Col xs={1}/>
      <Col xs={4}>
        <ImgNickBook/>
      </Col>
      <Col xs={7} css={css`padding-left: 45px!important;`}>
        <H2>
          Nick A. Pezzente (Author)
        </H2>
        <P>
          Nick is an ordinary man who stepped out in Faith in hopes of accomplishing the extraordinary through God's strength. "Honor Thy Parents" is an inspired creative narrative account written from personal experience.
        </P>
      </Col>
    </Row>
    <Row>
      <Col>
        <P>In September of 1997, Nick Pezzente, his wife Kimberly, and their three sons,  left Southern California for Connecticut to care for his two bedridden grandparents. What was thought to be a three year commitment, turned into a 10 year on-the-job training course to becoming a 24/7 caregiver.</P>
        <P>Out of love and obedience, Nick left his profession in the golf, contracting, and mortgage business to take on the calling of the Lord' s work.  "It was during the most successful income stages in my life that my wife and I chose to walk away from all that we had - family, church, friends - to walk into the unknown realm of providing care for grandparents who had no one to care for them or act on their behalf."</P>
        <P>The verse, "But if a widow has children or grandchildren, these should learn first of all to put their religion into practice by caring for their own family and so repaying their own parents, for this is pleasing to God." 1Timothy 5:4, provided the inspiration and direction they would need to prepare them for the uncertain circumstances they would face.  In an extraordinary leap of faith, Nick and his family saw the hand of God move on their behalf from the moment they purposed in their hearts to trust in the Lord - even by providing guidance through their eight-year-old son who proposed the answer to the means by which the family would move across the country.</P>
        <P>Over the years, Nick had been exhorted to put pen to paper and ‘tell the story’ so that others may be encouraged. Nick is compelled to share his experience with anyone who is currently caring for an elderly family member or who may be faced with that possibility.  It is a story of an ordinary family accomplishing the extraordinary through faith in God. And most importantly, to share with the reader how love, commitment and service to others can soften their hearts, that they too may come to know the saving Grace of our Lord and Savior Jesus Christ.</P>
      </Col>
    </Row>
  </Container>
)

export default AboutAuthor